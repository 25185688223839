import React from 'react';

import './styles/Header.css';

import logo_image from '../assets/logo-without-background.png';

function Header(props) {
	return (
		<header>
			<div className='header-content'>
				<a href='/' className='header-logo'>
					<img src={logo_image} alt='PI symbol' />
					<span>
						JULIANNE<br></br>LOBATO
					</span>
				</a>
				<div className='header-items'>
					<a href='/' className={props.location === 'home' ? 'header-item-active' : ''}>
						HOME
					</a>
					<a href='/background/' className={props.location === 'backdrop' ? 'header-item-active' : ''}>
						BACKGROUND
					</a>
					<a href='/career/' className={props.location === 'journey' ? 'header-item-active' : ''}>
						CAREER
					</a>
					<a href='/connect/' className={props.location === 'connect' ? 'header-item-active' : ''}>
						CONNECT
					</a>
				</div>
			</div>
		</header>
	);
}

export default Header;
