import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

import Backdrop from '../views/Backdrop.js';
import Connect from '../views/Connect.js';
import DefaultError from '../views/DefaultError.js';
import Home from '../views/Home.js';
import Career from '../views/Career.js';

class Routes extends Component {
	render() {
		const { language, location, setLocation } = this.props;
		return (
			<AnimatedSwitch atEnter={{ opacity: 0 }} atLeave={{ opacity: 0 }} atActive={{ opacity: 1 }} className='routes'>
				<Route
					path='/'
					exact={true}
					render={() => {
						if (location !== 'home')
							setLocation('home');
						return <Home language={language} />;
					}}
				/>
				<Route
					path='/background/:section/'
					render={({ match }) => {
						if (location !== 'backdrop')
							setLocation('backdrop');
						return <Backdrop url={match} />;
					}}
				/>
				<Route
					path='/background/'
					render={({ match }) => {
						if (location !== 'backdrop')
							setLocation('backdrop');
						return <Backdrop url={match} />;
					}}
				/>
				<Route
					path='/connect/'
					render={() => {
						if (location !== 'connect')
							setLocation('connect');
						return <Connect />;
					}}
				/>
				<Route
					path='/career/'
					render={() => {
						if (location !== 'journey')
							setLocation('journey');
						return <Career />;
					}}
				/>
				<Route
					render={() => {
						if (location !== 'error')
							setLocation('error');
						return <DefaultError />;
					}}
				/>
			</AnimatedSwitch>
		);
	}
}

export default Routes;
