import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';

import './App.css';

import Footer from './components/Footer.js';
import Header from './components/Header.js';
import Routes from './components/Routes.js';

class App extends React.Component {
	constructor(){
		super();
		this.state = {
			location: 'home',
			language: localStorage.getItem('JL__language') || 'en'
		}

		this.setLocation = this.setLocation.bind(this);
		this.setLanguage = this.setLanguage.bind(this);
	}

	setLocation(value){
		this.setState({location: value})
	}

	setLanguage(value){
		this.setState({language: value})
		localStorage.setItem('JL__language', value);
	}

	render() {
		const { language, location } = this.state;
		return (
			<Router>
				<main>
					<Header language={language} location={location} />
					<Routes language={language} location={location} setLocation={this.setLocation} />
					<Footer language={language} location={location} setLanguage={this.setLanguage} />
				</main>
				<SpeedInsights />
				<Analytics />
			</Router>
		)
	}
}

export default App;
