import React, { useState } from 'react';

import './styles/Backdrop.css';

import backdrop_image from '../assets/backdrop.webp';

function Backdrop(props) {
	const section = props.url.params.section || 'tech';
	const [option, setOption] = useState(section.toUpperCase());
	const options = ['TECH', 'BRIDGE', 'SCOUTING', 'PHOTOGRAPHY'];
	return (
		<section className='backdrop'>
			<div className='backdrop-image'>
				<div className='backdrop-title'>
					<div className='backdrop-line'></div>
					<div>Decoding Me:</div>
					<div>Roots & Interests</div>
				</div>
				<img src={backdrop_image} alt='Me' loading='lazy' />
			</div>
			<div className='backdrop-content'>
				<div className='backdrop-options'>
					{options.map((item, index) => (
						<div
							key={index}
							className={option === item ? 'backdrop-option-active' : 'backdrop-option-inactive'}
							onClick={() => setOption(item)}
						>
							{item}
						</div>
					))}
				</div>
				<div className='backdrop-text'>
					{option === 'SCOUTING' ? (
						<div>
							I started in the Scouts when I was 7 and stayed until I was 18, only stepping away when college life got too
							busy. But the lessons I learned during those years are still a big part of who I am.
							<br></br>
							<br></br>
							Scouting taught me so much about leadership, teamwork, being independent, and how to be a good citizen. Through
							all the activities and challenges, I learned how important it is to help out in the community, take initiative,
							and work well with others.
							<br></br>
							<br></br>Some of my proudest moments were earning important badges. As a Cub Scout, I got the "Cruzeiro do Sul,"
							and later as a Scout, I earned the "Lis de Ouro" and a global environmental badge. They reflect my personal
							growth and my commitment to always challenging myself to keep learning and evolving.
							<br></br>
							<br></br>
							Throughout my passage, I also held leadership roles within my troop, helping to guide others. These experiences
							really shaped who I am, teaching me resilience, empathy, and a strong desire to make a positive impact wherever
							I can.
						</div>
					) : option === 'BRIDGE' ? (
						<div>
							In the world of card games, Bridge stands out as something special, even though a lot of people still haven't
							had the chance to discover it. In Brazil, it's not as well-known as other games, but for those who play, it's
							often called the "chess of cards" — a mental sport that pushes your mind and hooks you in.
							<br></br>
							<br></br>I must confess, I was once among those unaware. I stumbled upon it while studying math in college. At
							first, I was hesitant to dive into this complex world of cards and strategy. But once I gave it a try, I
							realized how fascinating it was, and I got completely hooked.
							<br></br>
							<br></br>
							Bridge isn't about luck, it's all about logic and strategy. Over the years,I've had the opportunity to play in
							different tournaments, including bigger ones like the South American Championship and even a World Championship
							in China.
							<br></br>
							<br></br>
							Although I still have much to learn about the game, one thing is certain: Bridge is a journey of continuous
							learning. It takes a few months to grasp the basic rules, and a lifetime to master its nuances and complexities.
						</div>
					) : option === 'TECH' ? (
						<div>
							My relationship with technology began early, influenced by my family - both my parents work in the field.
							However, my own passion for it didn't show right away. Even though I was always interested, I was initially
							steered towards other paths, especially because of my strength in math.
							<br></br>
							<br></br>
							In school, I considered careers in civil engineering or architecture, fascinated by the exact sciences. But I
							soon realized those fields didn't align with what I was looking for. Architecture wasn't quite what I had
							imagined, and civil engineering involved more physics than I cared for.
							<br></br>
							<br></br>
							In a somewhat comical twist, an episode at school led me to the counselor's office where later he engaged me in
							a discussion about my future aspirations and asked if I had ever considered a career in IT. Without going into
							all the details here, that moment - originally a scolding - became a turning point and a story I love sharing.
							It felt like a light bulb turned on, and I realized programming had been what I wanted all along.<br></br>
							<br></br>
							Since then, I've fully embraced my passion for technology, always looking for ways to learn and grow in this
							exciting field. Every day, I discover new opportunities and challenges, and I'm eager to continue exploring this
							dynamic and innovative world.
						</div>
					) : (
						<div>
							Photography became a part of my life through my mom. She's been into it since she was young, always having a
							camera around to capture our moments together. Naturally, I grew up playing with her camera.
							<br></br>
							<br></br>
							Few years ago, I found her old analog camera, and it completely hooked me. There's something special about the
							wait—taking a photo and not knowing exactly how it'll turn out until later. But it's been a bumpy ride: I've
							lost rolls of film, overexposed shots, and made all kinds of mistakes. Still, I've learned to see the world
							differently, noticing details, colors, and textures I never paid attention to before.
							<br></br>
							<br></br>
							Photography has become a creative outlet for me, especially when I travel. Every place, whether it's a busy city
							or a quiet natural spot, gives me something new to explore through my lens.
							<br></br>
							<br></br>
							Now, I find myself bouncing between digital and analog photography. Digital is great for experimenting and
							capturing tons of moments, but analog slows me down in the best way. Every shot feels intentional, and there's
							this unique magic in waiting for the film to develop and reveal its imperfections, which make each photo feel
							even more special.
						</div>
					)}
				</div>
			</div>
		</section>
	);
}

export default Backdrop;
