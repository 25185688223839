import React from 'react';

import './styles/Career.css';

import career_image from '../assets/career.webp';

function Career() {
	return (
		<section className='career'>
			<div className='career-image'>
				<img src={career_image} alt='Me' />
			</div>
			<div className='career-content'>
				<div className='career-section'>
					<div className='career-line'></div>
					<div className='career-title'>
						<span>Work</span>
					</div>
					<div className='career-item'>
						<div className='career-right'>
							<div className='career-item-title'>Magroove - Software Developer</div>
							<div className='career-item-description'>
								I develop web pages and a mobile app integrated with databases using mostly Python, React and MySQL. I
								handle containerization and deployment tasks using Docker and Kubernetes, automate processes with Jenkins,
								and conduct daily code reviews using JIRA and Gitflow. Additionally, I have experience managing Google
								services like Ads, Analytics, Cloud, and Firebase, as well as AWS services such as S3, RDS, SES, EC2, and
								Route 53. My role involves overseeing the entire development lifecycle, ensuring efficient deployment, and
								maintaining applications.
							</div>
							<div className='career-positions'>
								<div className='career-positions-left'>
									<div className='career-positions-circle'></div>
									<div className='career-positions-line'></div>
									<div className='career-positions-circle'></div>
									<div className='career-positions-line'></div>
									<div className='career-positions-circle'></div>
								</div>
								<div className='career-positions-right'>
									<div className='career-item-subtitle'>
										Full Stack Developer <span> • Jul 2022 - Present</span>
									</div>
									<div className='career-item-subtitle'>
										Full Stack Developer Jr <span> • Sep 2021 - Jun 2022</span>
									</div>
									<div className='career-item-subtitle'>
										Developer Intern <span> • Jul 2020 - Aug 2021</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='career-section'>
					<div className='career-line'></div>
					<div className='career-title'>
						<span>Education</span>
					</div>
					<div className='career-item'>
						<div className='career-right'>
							<div className='career-item-title'>Instituto Superior de Engenharia de Lisboa - Politécnico de Lisboa</div>
							<div className='career-item-description'></div>
							<div className='career-positions'>
								<div className='career-positions-left'>
									<div className='career-positions-circle'></div>
								</div>
								<div className='career-positions-right'>
									<div className='career-item-subtitle'>
										IT & Computer Engineering <span> • Sep 2023 - Present</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='career-item'>
						<div className='career-right'>
							<div className='career-item-title'>Instituto de Matemática e Estatística - Universidade de São Paulo</div>
							<div className='career-item-description'></div>
							<div className='career-positions'>
								<div className='career-positions-left'>
									<div className='career-positions-circle'></div>
									<div className='career-positions-line'></div>
									<div className='career-positions-circle'></div>
								</div>
								<div className='career-positions-right'>
									<div className='career-item-subtitle'>
										Applied Mathematics <span> • Jan 2021 - Jul 2023</span>
									</div>
									<div className='career-item-subtitle'>
										Statistics <span> • Mar 2017 - Dec 2020</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Career;
