import React from 'react';

import './styles/Connect.css';

function Connect() {
	return (
		<section className='connect'>
			<div className='connect-box'>
				<div className='home-line'></div>
				<div className='connect-title'>
					How To<br></br>Contact Me
				</div>
				<div className='connect-text'>
					If you're keen on getting in touch, feel free to reach out to me via social media or drop me an email!
					<br></br>
					<br></br>
					{/*While I'm presently content with my current role and not pursuing new opportunities, */}I'm always open to expanding
					my network and staying connected. If you're a tech recruiter or looking to collaborate on a project, I'd welcome the
					chance to connect on LinkedIn. I'm happy to provide referrals or lend a hand whenever possible.
					<br></br>
					<br></br>
					Let's stay in touch and explore potential collaborations together.
				</div>
				<a href='mailto:contato@julobato.com'>
					<div className='home-cta'>CONTACT</div>
				</a>
			</div>
			<div className='connect-box'>
				<div className='connect-info'>
					<span className='connect-info-item-title'>Email:</span>
					<a href='mailto:contato@julobato.com' className='connect-info-item-text'>
						contato@julobato.com
					</a>
					<span className='connect-info-item-title'>Social:</span>
					<a href='https://github.com/julobato/' target='_blank' rel='noreferrer' className='connect-info-item-text'>
						GitHub
					</a>
					<a href='https://instagram.com/juliannelobato/' target='_blank' rel='noreferrer' className='connect-info-item-text'>
						Instagram
					</a>
					<a
						href='https://www.linkedin.com/in/julianne-lobato/'
						target='_blank'
						rel='noreferrer'
						className='connect-info-item-text'
					>
						LinkedIn
					</a>
					<a href='https://vsco.co/jlo/' target='_blank' rel='noreferrer' className='connect-info-item-text'>
						VSCO
					</a>
				</div>
			</div>
		</section>
	);
}

export default Connect;
