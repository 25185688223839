import React from 'react';

import './styles/DefaultError.css';

function DefaultError() {
	return (
		<section className='error'>
			<div className='error-line'></div>
			<div className='error-title'>Error 404: Lost in the Matrix</div>
			<div className='error-text'>
				The page you were looking for doesn't exist. You may have mistyped the address or the page may have moved.
			</div>
			<a href='/'>
				<div className='error-cta'>GO TO HOMEPAGE</div>
			</a>
		</section>
	);
}

export default DefaultError;
