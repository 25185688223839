import React from 'react';

import './styles/Footer.css';

const TEXT = {
	en: {
		career: 'Career',
		contact: 'Contact',
		discover: 'Discover',
		discover_more: 'DISCOVER MORE',
		footer: 'Cooked up by yours truly © 2024',
		home: 'Home'
	},
	pt: {
		career: 'Carreira',
		contact: 'Contato',
		discover: 'Descubra',
		discover_more: 'DESCUBRA MAIS',
		footer: 'Feito pela própria © 2024',
		home: 'Início'
	}
};

function Footer(props) {
	const { language } = props;
	const pages = ['/', 'background', 'career', 'connect'];
	return (
		<footer>
			<div className='footer-desktop-content'>
				<span>{TEXT[language].footer}</span>
				{/*<div className='footer-desktop-languages'>
					<span className='footer-language' onClick={() => setLanguage('pt')}>
						PT
					</span>
					<span className='footer-language-divider'>|</span>
					<span className='footer-language' onClick={() => setLanguage('en')}>
						EN
					</span>
				</div>*/}
			</div>
			{props.location === 'home' ? (
				<div className='footer-mobile-content'>
					<a href='/connect/' className='footer-mobile-back'>
						<span className='material-symbols-outlined'>arrow_back</span>
						{TEXT[language].contact}
					</a>
					<div className='footer-mobile-box'>
						{pages.map((item, index) => (
							<div
								key={index}
								onClick={() => (window.location.href = item)}
								className={index === 0 ? 'footer-mobile-step-active' : 'footer-mobile-step'}
							></div>
						))}
					</div>
					<a href='/background/' className='footer-mobile-forward'>
						Discover<span className='material-symbols-outlined'>arrow_forward</span>
					</a>
				</div>
			) : props.location === 'backdrop' ? (
				<div className='footer-mobile-content'>
					<a href='/' className='footer-mobile-back'>
						<span className='material-symbols-outlined'>arrow_back</span>
						{TEXT[language].home}
					</a>
					<div className='footer-mobile-box'>
						{pages.map((item, index) => (
							<div
								key={index}
								onClick={() => (window.location.href = item)}
								className={index === 1 ? 'footer-mobile-step-active' : 'footer-mobile-step'}
							></div>
						))}
					</div>
					<a href='/career/' className='footer-mobile-forward'>
						Career<span className='material-symbols-outlined'>arrow_forward</span>
					</a>
				</div>
			) : props.location === 'journey' ? (
				<div className='footer-mobile-content'>
					<a href='/' className='footer-mobile-back'>
						<span className='material-symbols-outlined'>arrow_back</span>
						{TEXT[language].discover}
					</a>
					<div className='footer-mobile-box'>
						{pages.map((item, index) => (
							<div
								key={index}
								onClick={() => (window.location.href = item)}
								className={index === 2 ? 'footer-mobile-step-active' : 'footer-mobile-step'}
							></div>
						))}
					</div>
					<a href='/connect/' className='footer-mobile-forward'>
						{TEXT[language].contact}
						<span className='material-symbols-outlined'>arrow_forward</span>
					</a>
				</div>
			) : (
				<div className='footer-mobile-content'>
					<a href='/career/' className='footer-mobile-back'>
						<span className='material-symbols-outlined'>arrow_back</span>
						{TEXT[language].career}
					</a>
					<div className='footer-mobile-box'>
						{pages.map((item, index) => (
							<div
								key={index}
								onClick={() => (window.location.href = item)}
								className={index === 3 ? 'footer-mobile-step-active' : 'footer-mobile-step'}
							></div>
						))}
					</div>
					<a href='/' className='footer-mobile-forward'>
						{TEXT[language].home}
						<span className='material-symbols-outlined'>arrow_forward</span>
					</a>
				</div>
			)}
		</footer>
	);
}

export default Footer;
