import React from 'react';

import './styles/Home.css';

import home_image from '../assets/home2.webp';
import home_mobile_image from '../assets/home-mobile.webp';

const TEXT = {
	en: {
		hello: 'Hello, there!',
		text1: `I'm a software developer, photography enthusiast, and former girl scout. I may not be able to start a fire with just sticks, but I can solve problems logically and efficiently.`,
		text2: `In my free time, you can find me behind the lens capturing the beauty of the world, planning my next cookie empire, or just enjoying a good game of bridge. I'm a versatile and dynamic individual, always ready to take on new challenges and learn something new.`,
		title: 'From Algorithms to Apertures, Embracing the Art of Creation.',
		discover: 'DISCOVER MORE'
	},
	pt: {
		hello: 'Olá!',
		text1: `Sou desenvolvedora de software, entusiasta da fotografia e fui escoteira por mais de dez anos. Pode ser que eu não consiga acender uma fogueira só com gravetos, mas sou ótima em resolver problemas de forma lógica e eficiente.`,
		text2: `No meu tempo livre, você geralmente me encontra atrás da câmera capturando a beleza do mundo, planejando meu próximo império de biscoitos ou jogando uma partida de bridge. Sou uma pessoa versátil, sempre pronta para enfrentar novos desafios e aprender algo novo.`,
		title: 'Dos Algoritmos às Aberturas, Abraçando a Arte da Criação.',
		discover: 'DESCUBRA MAIS'
	}
};

function Home(props) {
	const { language } = props;
	const text = TEXT[language];
	return (
		<section className='home'>
			<div className='home-line'></div>
			<div className='home-image'>
				<img className='home-image-desktop' src={home_image} alt='Me' loading='lazy' />
				<div className='home-image-mobile-box'>
					<img className='home-image-mobile' src={home_mobile_image} alt='Me' loading='lazy' />
					<div className='home-image-overlay'></div>
				</div>
			</div>
			<div className='home-title'>
				<span>{text.title}</span>
			</div>
			<div className='home-text'>
				<br></br>
				{text.hello}
				<br></br>
				<br></br>
				{text.text1}
				<br></br>
				<br></br>
				{text.text2}
			</div>
			<a href='/background/'>
				<div className='home-cta'>{text.discover}</div>
			</a>
		</section>
	);
}

export default Home;
